import React, { Fragment } from 'react'
import Link from 'gatsby-link'

import styled from 'styled-components'
import lodash from 'lodash'

import parameters from '@parameters'

const TagList = ({ className, date, tags }) => {
  return (
    <Fragment>
      {(date || tags) && (
        <nav className={className}>
          {date && <time dateTime={date}>{date}</time>}
          {tags && (
            <ul>
              {(tags || []).map((tag, id) => {
                return (
                  <li key={id}>
                    <Link
                      to={`/${parameters.tags.prefix(lodash.kebabCase(tag))}`}
                    >
                      {tag}
                    </Link>
                  </li>
                )
              })}
            </ul>
          )}
        </nav>
      )}
    </Fragment>
  )
}

export default styled(TagList)`
  time,
  li {
    display: inline-block;

    text-transform: uppercase;

    padding-top: ${props => props.theme.dimen.tag.y};
    padding-bottom: ${props => props.theme.dimen.tag.y};

    padding-right: ${props => props.theme.dimen.tag.x};
    padding-left: ${props => props.theme.dimen.tag.x};

    background-color: ${props => props.theme.colors.nav.bg};

    a {
      color: ${props => props.theme.colors.nav.text};
      text-decoration: none;
      :hover {
        text-decoration: underline;
      }
    }
  }

  ul > li,
  ol > li:not(:first-child) {
    display: block;

    ${props => props.theme.media.sm`
      display: inline-block;
      padding-left: ${props => props.theme.dimen.tag.x};
    `}
  }

  time,
  li:not(:last-child) {
    margin-right: ${props => props.theme.dimen.tag.x};
    margin-bottom: ${props => props.theme.dimen.tag.x};
  }

  ul {
    display: none;
    ${props => props.theme.media.md`
      display: inline-block;
    `}
  }
`
