import React, { Fragment } from 'react'
import { Markdown } from 'react-markdown-tree'
import { graphql } from 'gatsby'

import Article from '@components/post/article'
import Layout from '@components/layout'
import BannerList from '@components/banner/list'
import LightMasthead from '@components/banner/light-masthead'

export default ({
  data: {
    markdownRemark: {
      html,
      frontmatter: {
        authors,
        date,
        tags,
        title,
        keywords,
        preamble,
        previewImage,
        masthead,
        summary,
        type,
      },
    },
  },
  location,
}) => {
  const PageMasthead = ({ masthead }) => {
    if (masthead === null) return <Fragment />
    if (masthead.show !== true) return <Fragment />
    return (
      <LightMasthead
        backgroundImage={masthead.backgroundImage}
        backgroundColor={masthead.colors && masthead.colors.background}
        textColor={masthead.colors && masthead.colors.text}
        markerColor={masthead.colors && masthead.colors.marker}
        actionColors={masthead.colors && masthead.colors.action}
        title={masthead.title}
        subtitle={masthead.subtitle}
        actionLabel={masthead.action && masthead.action.label}
        actionTarget={masthead.action && masthead.action.target}
        actionDisplay={masthead.action && masthead.action.display}
      >
        {masthead.text && <Markdown>{masthead.text}</Markdown>}
      </LightMasthead>
    )
  }

  const image =
    (previewImage &&
      previewImage.childImageSharp &&
      previewImage.childImageSharp.general) ||
    undefined

  return (
    <Layout
      title={title}
      image={image}
      keywords={keywords}
      description={summary}
      pathname={location.pathname}
      type={type}
    >
      <BannerList>
        <PageMasthead masthead={masthead} />
      </BannerList>
      <Article
        authors={authors}
        content={html}
        date={date}
        preamble={preamble}
        tags={tags}
        title={title}
      />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      ...ArticleFragment
      ...LayoutFragment
      ...LightMastheadFragment
    }
  }
`
