import React, { Fragment } from 'react'
import { graphql } from 'gatsby'

import styled from 'styled-components'
import { grid } from 'styled-components-grid'

import TagList from '@components/post/tag-list'
import Text from '@components/text'

const ListSentence = styled(({ className, items }) => {
  if (items === null || items === undefined) return <Fragment />

  return (
    <nav className={className}>
      <ol itemProp="">
        {(items || []).map((item, id) => {
          return (
            <li key={id} itemProp="author">
              {item.name}
            </li>
          )
        })}
      </ol>
    </nav>
  )
})`
  font-size: ${props => props.theme.font._.p.size};
  line-height: ${props => props.theme.font._.p.lineHeight};

  ${props => props.theme.media.sm`
    font-size: ${props => props.theme.font.sm.p.size};
    line-height: ${props => props.theme.font.sm.p.lineHeight};
  `}

  ${props => props.theme.media.md`
    font-size: ${props => props.theme.font.md.p.size};
    line-height: ${props => props.theme.font.md.p.lineHeight};
  `}

  background-color: ${props => props.theme.colors.nav.bg};
  display: none;
  ${props => props.theme.media.md`
    display: block;
  `}

  > ul,
  > ol {
    padding-left: ${props => props.theme.dimen.tag.x};

    li {
      display: inline-block;
      padding-right: ${props => props.theme.dimen.tag.x};
      padding-top: ${props => props.theme.dimen.tag.y};
      padding-bottom: ${props => props.theme.dimen.tag.y};

      a {
        color: ${props => props.theme.colors.foreground('dark')};
        text-decoration: none;
        :hover {
          text-decoration: underline;
        }
      }
    }

    li:last-child:not(:first-child) {
      :before {
        content: "and ";
      }
    }

    li:not(:last-child):not(:nth-last-child(2)) {
      :after {
        content: ",";
      }
    }
  }
`

const Article = ({
  authors,
  className,
  content,
  date,
  preamble,
  tags,
  title,
}) => {
  return (
    <article className={className}>
      <header>
        <TagList date={date} tags={tags} />
        <h1>{title}</h1>
        {preamble && <p>{preamble}</p>}
        <ListSentence items={authors} />
      </header>

      <Text content={content} />
    </article>
  )
}

export default styled(Article)`
  ${grid({})}

  justify-content: space-between;
  align-items: center;

  margin-left: auto;
  margin-right: auto;

  padding-right: ${props => props.theme.dimen.main.x};
  padding-left: ${props => props.theme.dimen.main.x};
  padding-top: ${props => props.theme.dimen.main.y};

  ${props => props.theme.media.xl`
    width: ${props => props.theme.breakpoints.xl}px;
  `}

  header {
    ${grid.unit({ size: 1 })}

    > h1 {
      display: none;
    }

    > nav,
    > h1,
    > p
    {
      max-width: ${props => props.theme.breakpoints.md}px;
      margin-left: auto;
      margin-right: auto;
    }

    h1 {
      font-size: ${props => props.theme.font._.h1.size};
      line-height: ${props => props.theme.font._.h1.lineheight};
      font-weight: ${props => props.theme.fontWeights.strong};

      ${props => props.theme.media.sm`
        font-size: ${props => props.theme.font.sm.h1.size};
        line-height: ${props => props.theme.font.sm.h1.lineheight};
      `}

      ${props => props.theme.media.md`
        font-size: ${props => props.theme.font.md.h1.size};
        line-height: ${props => props.theme.font.md.h1.lineheight};
      `}
    }

    p {
      font-size: ${props => props.theme.font._.p.size};
      line-height: ${props => props.theme.font._.p.lineheight};

      ${props => props.theme.media.sm`
        font-size: ${props => props.theme.font.sm.p.size};
        line-height: ${props => props.theme.font.sm.p.lineHeight};
      `}

      ${props => props.theme.media.md`
        font-size: ${props => props.theme.font.md.p.size};
        line-height: ${props => props.theme.font.md.p.lineHeight};
      `}

      padding-top: ${props => props.theme.dimen.articlePreamble.bottom};
      padding-bottom: ${props => props.theme.dimen.articlePreamble.bottom};
    }
  }

  > p,
  > section {
    ${grid.unit({ size: 1 })}
    margin-left: auto;
    margin-right: auto;
    ${props => props.theme.media.md`
      max-width: ${props => props.theme.breakpoints.md}px;
    `}
  }
`

export const query = graphql`
  fragment ArticleFragment on MarkdownRemark {
    frontmatter {
      preamble
      authors {
        name
        github
      }
      tags
      type
    }
  }
`
